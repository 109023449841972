//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
import cookies from "vue-cookies";
import { version } from "../../package";
export default {
  data: () => ({
    appURL: "",
    appVersion: version,
    apiVersion: "",
    appNewVersion: "",
    appVUpdate: cookies.get("ACVUpdate"),
    appUsername: "",
    appNavigatorName: "Not available",
    appCookie: "No",
    appGeolocation: "Not available",
    appgpsLoc: "",
    appServerTime: "",
    dialogUpdate: true,
    newVersion: "",
  }),
  created() {},
  mounted() {
    this.getAPIVersion();
    this.getServerTime();
    this.appURL = window.location.origin;
    this.newVersion = this.$route.query.version;
    this.appUsername = sessionStorage.getItem("UserName");


    this.appNavigatorName = navigator.userAgent;

    if ("cookieEnabled" in navigator) {
      this.appCookie = "Yes"
    }

    if ("geolocation" in navigator) {
      this.appGeolocation = "Available";
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.appgpsLoc =
            " - " + pos.coords.latitude + ", " + pos.coords.longitude;
        },
        (err) => {
          this.appgpsLoc = " (" + err.message + ")";
        }
      );
    }
  },
  methods: {
    goBack() {
      this.$router.push("/mainmenu");
    },
    goMenu() {
      this.$router.push("/mainmenu");
    },
    getAPIVersion() {
      var self = this;
      axios
        .get(api.URL() + "/api/v1/getapiversion", {
          token: sessionStorage.getItem("Token"),
        })
        .then((response) => {
          self.apiVersion = response.data;
        })
        .catch(function (error) {
          self.msgAlert(error.response.data, true);
        });
    },
    getServerTime() {
      axios
        .post(api.URL() + "/api/v1/getservertime", {
          token: sessionStorage.getItem("Token"),
          language: "en",
        })
        .then((response) => {
          if (response.error == null) {
            this.appServerTime =
              response.data.serverTimeString +
              ` ` +
              response.data.serverDateHour +
              `:` +
              response.data.serverDateMinute;
          }
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };
      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
